
              @import "@/assets/css/variables.scss";
            




































.trading_tool_webtrade {
  background: $secondary;
  padding: 60px 16px 16px 16px;
  border-radius: 8px;

  @include screen-mobile {
    padding: 32px 0;
  }

  .title {
    font-size: 32px;
    color: $white;
    line-height: 48px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 24px;

    @include screen-mobile {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 16px;
    }
  }

  .container {
    padding: 24px;
    background: #fafafa;
    box-shadow: 0px 8px 20px rgba(14, 18, 54, 0.1);
    border-radius: 16px;

    @include screen-mobile {
      padding: 0;
    }
  }
}
